import React, { useState, useContext, useEffect, useRef } from "react";
import _ from "lodash";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { motion, AnimatePresence, useInView } from "framer-motion";

export default function TickerElement({
   element,
   activeStyle,
   addTicker,
   parentRef,
   deleteTicker,
   duration,
}) {
   const ref = useRef(null);
   const isInView = useInView(ref, { root: parentRef, amount: "all" });

   const [loadedNext, setLoadedNext] = useState(false);

   useEffect(() => {
      if (isInView === true) {
         setLoadedNext(true);
      }
   }, [isInView]);

   useEffect(() => {
      if (loadedNext === true) {
         addTicker(element.id);
      }
   }, [loadedNext]);

   return (
      <Box
         sx={{
            position: "absolute",
            whiteSpace: "nowrap",
            pl: 4,
         }}
         component={motion.div}
         initial={{ y: "-50%", x: 1920 }}
         animate={{
            x: -2000,
         }}
         onAnimationComplete={() => {
            deleteTicker();
         }}
         transition={{
            ease: "linear",
            duration: activeStyle.duration,
         }}
      >
         <Stack direction='row'>
            {element.message}
            <Box ref={ref} sx={{ width: activeStyle.message.pr }} />
         </Stack>
      </Box>
   );
}
