import React, {
   useState,
   useRef,
   useEffect,
   useContext,
   useCallback,
} from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import TickerWrapper from "../components/ticker/TickerWrapper";
import Ticker from "../components/Ticker";
import { ScaleContext } from "../context/scale";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Viewer() {
   const [frameHeight, setFrameHeight] = useState();
   const [background, setBackground] = useState("none");

   const handleFrameHeight = (height) => {
      if (height) {
         setFrameHeight(height);
      }
   };

   const handleBackground = (color) => {
      if (color) {
         setBackground(color);
      }
   };

   return (
      <Box
         sx={{
            width: "100vw",
            height: "100vh",
            position: "relative",
            ...background,
         }}
      >
         <Box
            sx={{
               width: "100%",
               height: frameHeight,
               overflow: "hidden",
               position: "absolute",
               bottom: 0,
            }}
         >
            <TickerWrapper heightCallback={handleFrameHeight} key={"viewer"}>
               <Ticker backgroundCallback={handleBackground} />
            </TickerWrapper>
         </Box>
      </Box>
   );
}
