import React, { useState, useEffect } from "react";
import reactCSS from "reactcss";
import { ChromePicker, SketchPicker } from "react-color";

import Box from "@mui/material/Box";

const colorObj2rgba = (color) => {
   if (typeof color === "object") {
      return `rgba(${color.r},${color.g},${color.b},${color.a})`;
   } else {
      return color;
   }
};

const convertColor2Obj = (color) => {
   if (color.includes("#")) {
      return hexToRGB(color);
   } else {
      let rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      return { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] };
   }
};

const hexToRGB = (hex) => {
   var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
   return { r: r, g: g, b: b, a: 1 };
};

function ColorPicker({
   parent,
   property,
   initColor,
   inline,
   swatches,
   zIndex,
   callback,
}) {
   const [color, setColor] = useState(initColor);
   const [displayColorPicker, setDisplayColorPicker] = useState(false);

   const handleClick = () => {
      setDisplayColorPicker((prevState) => {
         return !prevState;
      });
   };

   const handleClose = () => {
      setDisplayColorPicker(false);
   };

   const handleChangeComplete = (color) => {
      setColor(color.rgb);
      callback(parent, property, colorObj2rgba(color.rgb));
   };

   return (
      <Box sx={{ position: "relative", zIndex: zIndex }}>
         {!inline && (
            <Box
               sx={{
                  padding: 1,
                  background: "#fff",
                  borderRadius: 1,
                  boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                  display: "inline-block",
                  cursor: "pointer",
               }}
               onClick={handleClick}
            >
               <Box
                  sx={{
                     width: 100,
                     height: 44,
                     borderRadius: 1,
                     background: colorObj2rgba(color),
                  }}
               />
            </Box>
         )}

         {!inline && displayColorPicker && (
            <Box sx={{ position: "absolute", zIndex: 100 }}>
               {swatches && (
                  <SketchPicker
                     color={color}
                     onChange={handleChangeComplete}
                     presetColors={swatches}
                  />
               )}

               {!swatches && (
                  <ChromePicker color={color} onChange={handleChangeComplete} />
               )}
            </Box>
         )}

         {inline && (
            <Box sx={{ position: "relative" }}>
               {swatches && (
                  <SketchPicker
                     color={color}
                     onChange={handleChangeComplete}
                     presetColors={swatches}
                  />
               )}

               {!swatches && (
                  <ChromePicker color={color} onChange={handleChangeComplete} />
               )}
            </Box>
         )}
      </Box>
   );
}

export default ColorPicker;
