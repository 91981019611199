import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import logo from "../../assets/logo.svg";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Auth({ children }) {
   let { uuid } = useParams();
   let { createdAt } = useParams();

   if (createdAt) {
      localStorage.setItem("verification", createdAt);
   }

   const [showForm, setShowForm] = useState(false);
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [loggedIn, setLoggedIn] = useState(false);
   const [error, setError] = useState("");

   const handleEmailUpdate = (e) => {
      const value = e.target.value;
      setEmail(value);

      if (e.key === "Enter") {
         e.target.blur();
         // SUBMIT PASSWORD
      }
   };

   const handlePasswordUpdate = (e) => {
      const value = e.target.value;
      setPassword(value);

      if (e.key === "Enter") {
         e.target.blur();
         // SUBMIT PASSWORD
      }
   };

   const login = () => {
      axios
         .post(SERVER_URL + "/checklogin", {
            id: uuid,
            email: email,
            password: password,
         })
         .then(function (response) {
            if (response.data.verified) {
               setLoggedIn(true);
               localStorage.setItem("verification", response.data.verification);
            } else {
               setError(
                  "Sorry, this login is not working.  Please check your Ticker URL, email, and password, then try again. "
               );
            }
         });
   };

   useEffect(() => {
      if (createdAt) {
         let href = window.location.href;
         href = href.slice(0, href.lastIndexOf("/"));

         // REDIRECT WITHOUT DATE
         window.location.href = href;
      } else {
         const verification = localStorage.getItem("verification");

         if (verification) {
            axios
               .post(SERVER_URL + "/checkverification", {
                  id: uuid,
                  code: verification,
               })
               .then(function (response) {
                  if (response.data.verified) {
                     setLoggedIn(true);
                     setShowForm(false);
                  } else {
                     setLoggedIn(false);
                     setShowForm(true);
                  }
               });
         } else {
            setLoggedIn(false);
            setShowForm(true);
         }
      }
   }, []);

   return (
      <>
         <AnimatePresence>
            {loggedIn && (
               <Box
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
               >
                  {children}
               </Box>
            )}
         </AnimatePresence>

         {!loggedIn && (
            <AnimatePresence>
               {showForm && (
                  <Box
                     component={motion.div}
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     className='pageBG'
                     sx={{
                        p: 4,
                        width: "100%",
                        minHeight: "100vh",
                        overflow: "scroll",
                        backgroundColor: "#333",
                        color: "#fff",
                        boxSizing: "border-box",
                     }}
                  >
                     {/* HEADER */}
                     <Stack
                        spacing={2}
                        alignItems='center'
                        justifyContent='center'
                     >
                        <img src={logo} width='100' />
                        <Typography variant='h2' sx={{ whiteSpace: "nowrap" }}>
                           Just a Ticker Dashboard
                        </Typography>
                     </Stack>

                     <Stack
                        alignItems='center'
                        sx={{ mt: 6 }}
                        component={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                     >
                        <Stack
                           alignItems='center'
                           spacing={2}
                           sx={{ width: 400 }}
                        >
                           <Typography variant='h4' sx={{ fontWeight: "bold" }}>
                              Login
                           </Typography>

                           <TextField
                              fullWidth
                              placeholder='Email'
                              inputProps={{
                                 autoFocus: true,
                              }}
                              name='email'
                              onChange={handleEmailUpdate}
                           />

                           <TextField
                              fullWidth
                              placeholder='Password'
                              inputProps={{
                                 autoFocus: true,
                              }}
                              type='password'
                              name='password'
                              onChange={handlePasswordUpdate}
                           />
                           <Button variant='primary' onClick={login}>
                              Login
                           </Button>
                        </Stack>

                        {error && (
                           <Typography
                              variant='h4'
                              color='secondary'
                              sx={{ mt: 4 }}
                              component={motion.div}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                           >
                              {error}
                           </Typography>
                        )}
                     </Stack>
                  </Box>
               )}
            </AnimatePresence>
         )}
      </>
   );
}
