import React, {
   useState,
   useRef,
   useEffect,
   useContext,
   useCallback,
} from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import TickerWrapper from "../components/ticker/TickerWrapper";
import TickerDemo from "../components/TickerDemo";
import { ScaleContext } from "../context/scale";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ViewerDemo() {
   const [frameHeight, setFrameHeight] = useState();

   const handleFrameHeight = (height) => {
      if (height) {
         setFrameHeight(height);
      }
   };

   return (
      <Box
         sx={{
            width: "100%",
            height: frameHeight,
            overflow: "hidden",
            position: "relative",
            bottom: 0,
         }}
      >
         <TickerWrapper heightCallback={handleFrameHeight} key={"viewer"}>
            <TickerDemo />
         </TickerWrapper>
      </Box>
   );
}
