import * as React from "react";
import { styled } from "@mui/material/styles";

import { Typography, Stack, Box } from "@mui/material";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import RemindMeTickers from "../components/RemindMeTickers";

const Accordion = styled((props) => (
   <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
   border: `1px solid ${theme.palette.divider}`,
   "&:not(:last-child)": {
      borderBottom: 0,
   },
   "&:before": {
      display: "none",
   },
}));

const AccordionSummary = styled((props) => (
   <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
   />
))(({ theme }) => ({
   backgroundColor:
      theme.palette.mode === "dark"
         ? "rgba(255, 255, 255, .05)"
         : "rgba(0, 0, 0, .03)",
   flexDirection: "row-reverse",
   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
   },
   "& .Mui-expanded p": {
      fontWeight: "bold !important",
      color: "#fd0c73",
   },
   "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
   },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
   padding: theme.spacing(4),
   borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const faqEntries = [
   {
      title: "What is Justaticker.com?",
      text: `Justaticker.com was created by Seattle-based live video broadcast & design company Punch Drunk Productions. We built JAT to fulfill a recurring need: a simple crawling text ticker that wouldn't "start over" when new entries are introduced. After successful internal use, we decided to make the app available for free for any live events and broadcasts.`,
   },
   {
      title: "How does the data cycle in the viewer?",
      text: `Every time new data is published, it is appended to the end of the data stream. When the crawling ticker comes back around, the new entries are now shown. The ticker is never "refreshed" visibly, so it's always displaying a constant stream of contiguous entries. `,
   },
   {
      title: "How do I delete a ticker?",
      text: "No need! Just let your ticker sit unused for 60 days and it will automatically be deleted.",
   },
   {
      title: "How do I get a username and password?",
      text: `You don't need a username or account for Justaticker. Simply enter your email after clicking the "create a ticker" button and you will receive a unique clicker URL and password. The password allows you to keep your ticker data private. Note that you cannot change the password, so be careful who you share the password with. The viewer link is always public with no password so you can share with your technical team, client, etc.`,
   },
   {
      title: "I lost my ticker URL. How do I get it re-sent? ",
      text: `Enter your email below, and we will send any active ticker URLs to that email address:`,
      component: RemindMeTickers,
   },
   {
      title: "How long does my ticker last? ",
      text: `Justaticker URLs stay active indefinitely as long as they are being used. If a ticker isn't accessed or viewed for 60 days, it will automatically expire and get deleted. If that happens and you need the ticker again, don't worry – just create a new ticker with your data. `,
   },
   {
      title: "Can I use Justaticker with Blackmagic products?",
      text: `Yes! You can open the viewer URL in a fullscreen web browser and capture the computer in your Blackmagic switcher just like any other computer source. Then you can key or crop the background out and overlay the ticker on your video stream.`,
   },
   {
      title: "Can I use Justaticker with VMIX, OBS, Wirecast, Streamyard, etc?",
      text: `Yes! Justaticker works like any web source – use the viewer URL and then make sure the background is set to transparent in order to overlay Justaticker on your video stream.`,
   },
   {
      title: "When would I want to use Justaticker?",
      text: `Any time you want crawling text on screen, like you would see on a live news channel. Some great uses are: recognizing donors at fundraisers, listing event participant names, displaying recent headlines, thanking sponsors – the uses are limitless. `,
   },
   {
      title: "Are there any limitations to the number or length of entries?",
      text: `Justaticker allows up to 1,000 entries and each entry can be up to 500 characters.`,
   },
   {
      title: "Can I input hyperlinks or rich text?",
      text: `No. All the data in Justaticker will be stripped of any rich formatting or hyperlinks, and displayed in the font and color chosen on the editor page. The viewer page is designed to be viewed in a broadcast, not in a clickable or dynamic interface. `,
   },
];

export default function CustomizedAccordions() {
   const [expanded, setExpanded] = React.useState();

   const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
   };

   return (
      <Box sx={{ mx: "auto", pb: 8, maxWidth: 1000 }}>
         <Typography variant='h3' sx={{ mb: 3 }}>
            FAQ
         </Typography>

         {faqEntries.map((entry, i) => (
            <Accordion
               expanded={expanded === "panel" + i}
               onChange={handleChange("panel" + i)}
               key={"accordiongPanel" + i}
            >
               <AccordionSummary
                  aria-controls={"panel" + i + "d-content"}
                  id={"panel" + i + "d-header"}
               >
                  <Typography>{entry.title}</Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Typography>{entry.text}</Typography>

                  {entry.component && <entry.component />}
               </AccordionDetails>
            </Accordion>
         ))}
      </Box>
   );
}
