import React, { useState, useContext, useEffect, useRef } from "react";
import _ from "lodash";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { motion, AnimatePresence } from "framer-motion";

export default function TickerElement({ element, activeStyle, duration }) {
   return (
      <Stack
         direction='row'
         sx={{
            pl: 4,
            transform: "translateY(-50%)",
         }}
      >
         {element.message} <Box sx={{ width: activeStyle.message.pr }} />
      </Stack>
   );
}
