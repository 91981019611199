import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Auth from "./components/editor/Auth";

import App from "./routes/App";
import Editor from "./routes/Editor";
import Viewer from "./routes/Viewer";
import Page from "./routes/Page";

import ViewerDemo from "./routes/ViewerDemo";
import CreateTickerForm from "./components/CreateTickerForm";

import "./global.css";

// THEME
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { colors, components } from "./theme/mui";
let theme = createTheme(colors, components);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <>
      <ThemeProvider theme={theme}>
         <CssBaseline />
         <Router>
            <Routes>
               <Route path='/' element={<App />} />
               <Route
                  path='/:uuid/editor/:createdAt?'
                  element={
                     <Auth>
                        <Editor />
                     </Auth>
                  }
               />
               <Route path='/:uuid/viewer' element={<Viewer />} />
               <Route path='/:urltitle' element={<Page />} />
               <Route path='/demo' element={<ViewerDemo />} />
               <Route path='/createform' element={<CreateTickerForm />} />
            </Routes>
         </Router>
      </ThemeProvider>
   </>
);
