export const colors = {
   typography: {
      fontFamily: `"Noto Sans", "Arial", "Helvetica", "sans-serif"`,
   },
   shadows: ["none"],
   palette: {
      text: {
         primary: "rgb(55, 53, 47)",
         secondary: "#fd0c73",
      },
      primary: {
         main: "rgb(55, 53, 47)",
      },
      secondary: {
         main: "#fd0c73",
      },
   },
};

export const components = {
   typography: {
      h1: {
         fontSize: "3rem",
         fontWeight: 900,
         letterSpacing: "-.09rem",
         color: colors.palette.text.primary,
      },
      h2: {
         fontSize: "1.6rem",
         fontWeight: 900,
         letterSpacing: "-.09rem",
         color: colors.palette.text.primary,
      },
      h3: {
         fontSize: "1.4rem",
         color: colors.palette.text.primary,
      },
      h4: {
         fontSize: "1rem",
         fontWeight: 700,
         letterSpacing: "-.03rem",
         color: colors.palette.text.primary,
      },
      h5: {
         fontSize: "1rem",
         color: colors.palette.text.secondary,
      },
      h6: {
         fontSize: ".8rem",
         color: colors.palette.text.secondary,
      },
   },
   components: {
      MuiMenu: {
         styleOverrides: {
            root: {
               "& .MuiList-root": {
                  backgroundColor: "#fff",
                  "& .Mui-focusVisible": {
                     backgroundColor: "transparent",
                  },
               },
               "& .MuiMenuItem-root": {
                  color: "#000",
                  "&:hover": {
                     backgroundColor: "rgba(0,0,0,.1)",
                  },
               },
            },
         },
      },
      MuiButton: {
         styleOverrides: {
            root: {
               fontWeight: "bold",
               textTransform: "uppercase",
            },
         },
         variants: [
            {
               props: { variant: "primary" },
               style: {
                  color: "#fff",
                  backgroundColor: "#fd0c73",
                  "&:hover": {
                     backgroundColor: "#777",
                  },
               },
            },
            {
               props: { variant: "secondary" },
               style: {
                  color: "#fff",
                  backgroundColor: "#333",
                  "&:hover": {
                     backgroundColor: "#777",
                  },
               },
            },
            {
               props: { variant: "delete" },
               style: {
                  color: "#f00",
                  backgroundColor: "transparent",
                  border: "1px solid #f00",
                  "&:hover": {
                     color: "#fff",
                     backgroundColor: "#de0004",
                  },
               },
            },
         ],
      },
   },
};
