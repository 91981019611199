import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import pdlogo from "../assets/pdlogo.png";

export default function About() {
   return (
      <Stack
         spacing={2}
         justifyContent='center'
         alignItems='center'
         sx={{
            color: "#999",
            fontSize: ".8em",
            mx: "auto",
            maxWidth: 1000,
            textAlign: "center",
         }}
      >
         <a
            href='https://punch-drunk.com'
            style={{ textDecoration: "none", border: 0 }}
         >
            <img src={pdlogo} style={{ width: 100 }} />
         </a>

         <Box>
            <a href='terms'>Terms &amp; Conditions</a>
            <br />
            <a href='privacy'>Privacy Policy</a>
         </Box>
      </Stack>
   );
}
