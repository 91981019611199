import React, { useState, useRef, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import axios from "axios";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

// VALIDATION
import { useFormik } from "formik";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function CreateTickerForm() {
   const [instance, setInstance] = useState(false);
   const [reminder, setReminder] = useState(false);
   const [showCreate, setShowCreate] = useState(true);
   const [showEmailCollection, setShowEmailCollection] = useState(false);
   const [showEmailReminder, setShowEmailReminder] = useState(false);

   // FUNCTIONS
   const addInstance = (email) => {
      if (email) {
         axios
            .post(SERVER_URL + "/createticker", {
               email: email,
            })
            .then(function (response) {
               if (response) {
                  setInstance(true);
               }
            });
      }
   };

   const allTickerInstances = (email) => {
      if (email) {
         axios
            .post(SERVER_URL + "/sendtickers", {
               email: email,
            })
            .then(function (response) {
               if (response) {
                  if (response) {
                     setReminder(response.data);
                  }
               }
            });
      }
   };

   const validate = (values) => {
      const errors = {};

      if (!values.email) {
         errors.email = "Required";
      } else if (
         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
         errors.email = "Invalid email address";
      }

      return errors;
   };

   const formik = useFormik({
      initialValues: {
         email: "",
      },
      validate,
      onSubmit: (values) => {
         addInstance(values.email);
         setShowEmailCollection(false);
      },
   });

   const findTickers = useFormik({
      initialValues: {
         email: "",
      },
      validate,
      onSubmit: (values) => {
         allTickerInstances(values.email);
         setShowEmailReminder(false);
      },
   });

   return (
      <Box
         sx={{
            position: "relative",
            width: "100%",
            minHeight: 50,
         }}
      >
         <AnimatePresence>
            {showCreate && (
               <Stack
                  alignItems='center'
                  sx={{
                     overflow: "hidden",
                     position: "absolute",
                     left: "50%",
                     transform: "translateX(-50%)",
                     width: "100%",
                  }}
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  spacing={1}
               >
                  <Button
                     onClick={() => {
                        setShowCreate(false);
                        setShowEmailCollection(true);
                     }}
                     variant='primary'
                     sx={{ fontSize: "1.4rem" }}
                  >
                     Create a Ticker
                  </Button>
                  <Button
                     variant='outlined'
                     onClick={() => {
                        setShowCreate(false);
                        setShowEmailReminder(true);
                     }}
                     sx={{ fontSize: ".7rem" }}
                  >
                     Remind me what tickers I have already created
                  </Button>
               </Stack>
            )}
         </AnimatePresence>

         <AnimatePresence>
            {showEmailCollection && (
               <Box
                  sx={{
                     backgroundColor: "#fff",
                     overflow: "hidden",
                     boxSizing: "border-box",
                     maxWidth: 500,
                     width: "100%",
                     mx: "auto",
                     position: "relative",
                  }}
                  component={motion.div}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
               >
                  <Box sx={{ m: 4 }}>
                     <Button
                        onClick={() => {
                           setShowCreate(true);
                           setShowEmailCollection(false);
                        }}
                        sx={{
                           position: "absolute",
                           top: 0,
                           right: 0,
                        }}
                     >
                        X
                     </Button>

                     <Typography
                        variant='subtitle1'
                        sx={{ color: "#333", pb: 2 }}
                     >
                        Please enter your email address to create a ticker:
                     </Typography>

                     <form onSubmit={formik.handleSubmit}>
                        <Stack>
                           <TextField
                              type='email'
                              label='Email Address'
                              name='email'
                              id='email'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                           />

                           {formik.touched.email && formik.errors.email && (
                              <Typography variant='subtitle2'>
                                 {formik.errors.email}
                              </Typography>
                           )}

                           <Box
                              sx={{
                                 fontSize: ".75rem",
                                 textAlign: "left",
                                 color: "rgba(0,0,0,.5)",
                                 mt: 2,
                              }}
                           >
                              (We will not spam you or sign you up for a
                              newsletter – this is how you activate your
                              ticker.)
                           </Box>

                           <Button
                              name='submit'
                              type='submit'
                              variant='contained'
                              color='secondary'
                              disabled={
                                 formik.errors.email
                                    ? true
                                    : !formik.values.email
                                    ? true
                                    : false
                              }
                              sx={{ mt: 2 }}
                           >
                              Submit
                           </Button>
                        </Stack>
                     </form>
                  </Box>
               </Box>
            )}
         </AnimatePresence>

         <AnimatePresence>
            {showEmailReminder && (
               <Box
                  sx={{
                     backgroundColor: "#fff",
                     overflow: "hidden",
                     boxSizing: "border-box",
                     maxWidth: 500,
                     width: "100%",
                     mx: "auto",
                     position: "relative",
                  }}
                  component={motion.div}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
               >
                  <Box sx={{ m: 4 }}>
                     <Button
                        onClick={() => {
                           setShowCreate(true);
                           setShowEmailReminder(false);
                        }}
                        sx={{
                           position: "absolute",
                           top: 0,
                           right: 0,
                        }}
                     >
                        X
                     </Button>
                     <Typography
                        variant='subtitle1'
                        sx={{ color: "#333", pb: 2 }}
                     >
                        Please enter your email address to get a list of your
                        active tickers:
                     </Typography>

                     <form onSubmit={findTickers.handleSubmit}>
                        <Stack>
                           <TextField
                              type='email'
                              label='Email Address'
                              name='email'
                              id='email'
                              onChange={findTickers.handleChange}
                              onBlur={findTickers.handleBlur}
                              value={findTickers.values.email}
                           />

                           {findTickers.touched.email &&
                              findTickers.errors.email && (
                                 <Typography variant='subtitle2'>
                                    {findTickers.errors.email}
                                 </Typography>
                              )}

                           <Button
                              name='submit'
                              type='submit'
                              variant='contained'
                              color='secondary'
                              disabled={
                                 findTickers.errors.email
                                    ? true
                                    : !findTickers.values.email
                                    ? true
                                    : false
                              }
                              sx={{ mt: 2 }}
                           >
                              Submit
                           </Button>
                        </Stack>
                     </form>
                  </Box>
               </Box>
            )}
         </AnimatePresence>
         <AnimatePresence>
            {reminder && (
               <Stack
                  spacing={2}
                  key={instance}
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
               >
                  <Typography
                     variant='h4'
                     color='secondary'
                     align='center'
                     sx={{ px: 3 }}
                  >
                     {reminder}
                  </Typography>
               </Stack>
            )}
         </AnimatePresence>

         <AnimatePresence>
            {instance && (
               <Stack
                  spacing={2}
                  key={instance}
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
               >
                  <Typography
                     variant='h4'
                     color='secondary'
                     align='center'
                     sx={{ px: 3 }}
                  >
                     Congratulations, the ticker has been created. Please check
                     your email for Ticker URL and password.
                  </Typography>
               </Stack>
            )}
         </AnimatePresence>
      </Box>
   );
}
