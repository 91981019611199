import React, { useState, useRef, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ReactPlayer from "react-player";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import ViewerDemo from "./ViewerDemo";
import CreateTickerForm from "../components/CreateTickerForm";
import Faq from "./Faq";
import About from "../components/About";

import parse from "html-react-parser";
import logo from "../assets/logo.svg";
import pdlogo from "../assets/pdlogo.png";
import video from "../assets/video.mov";

import header1 from "../assets/header-1.png";
import header2 from "../assets/header-2.png";
import header3 from "../assets/header-3.png";

import step1 from "../assets/step-1.png";
import step2 from "../assets/step-2.png";
import step3 from "../assets/step-3.png";
import step4 from "../assets/step-4.png";
import step5 from "../assets/step-5.png";

import usePages from "../hooks/usePages";

function App() {
   const slides = [header1, header2, header3];
   const [slideIndex, setSlideIndex] = useState(0);

   const [pages, { getPages }] = usePages();
   const [showVideo, setShowVideo] = useState(true);

   useEffect(() => {
      const interval = setInterval(() => {
         setSlideIndex((prevIndex) => {
            if (prevIndex + 1 >= slides.length) {
               return 0;
            } else {
               return prevIndex + 1;
            }
         });
      }, 5000);
      return () => clearInterval(interval);
   }, []);

   useEffect(() => {
      getPages();
   }, []);

   // VARIANTS
   const variants = {
      visible: { opacity: 1 },
      hidden: { opacity: 0 },
   };

   return (
      <Box
         className='pageBG'
         sx={{
            p: 4,
            width: "100%",
            minHeight: "100vh",
            boxSizing: "border-box",
         }}
      >
         <Stack alignItems='center' sx={{ mx: "auto", pb: 8, maxWidth: 1000 }}>
            <Grid container alignItems='center' justifyContent='center'>
               <Grid item xs={9} sm={6} sx={{ textAlign: "center" }}>
                  <Box sx={{ p: 4 }}>
                     <img src={logo} width='100%' alt='Just a Ticker' />
                  </Box>
               </Grid>
               <Grid item xs={12} sm={12}>
                  <Box
                     sx={{ position: "relative", mb: 2 }}
                     component={motion.div}
                     initial='hidden'
                     animate={showVideo ? "visible" : "hidden"}
                     variants={variants}
                     transition={{ delay: 0.4, duration: 1 }}
                  >
                     <Box
                        sx={{
                           width: "100%",
                           height: "100%",
                           position: "absolute",
                           p: 0,
                           m: 0,
                           backgroundColor: "#fff",
                        }}
                     >
                        {slides.map((slide, i) => (
                           <AnimatePresence exitBeforeEnter key={"slide" + i}>
                              {i === slideIndex && (
                                 <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{
                                       opacity: 1,
                                    }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 1 }}
                                    style={{
                                       position: "absolute",
                                       top: 0,
                                       left: 0,
                                    }}
                                 >
                                    <img
                                       src={slide}
                                       width='100%'
                                       height='100%'
                                    />
                                 </motion.div>
                              )}
                           </AnimatePresence>
                        ))}
                     </Box>
                     <ViewerDemo />
                  </Box>
               </Grid>
            </Grid>
            <Stack sx={{ my: 5 }}>
               <Typography
                  variant='h2'
                  sx={{ px: 8, pb: 1, textAlign: "center" }}
                  component={motion.div}
                  initial={{ opacity: 0, y: -30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4, duration: 1 }}
               >
                  Free animated text ticker for live broadcast and streaming.
               </Typography>

               <Typography
                  variant='h3'
                  sx={{ px: 8, pb: 6, textAlign: "center" }}
                  component={motion.div}
                  initial={{ opacity: 0, y: 0, scale: 0.8 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  transition={{ delay: 0.8, duration: 1 }}
               >
                  From your friends at{" "}
                  <a href='https://punch-drunk.com' style={{}}>
                     Punch Drunk
                  </a>
                  .
               </Typography>

               <Box sx={{ mb: 8 }}>
                  <CreateTickerForm />
               </Box>
            </Stack>

            <Grid
               container
               justifyContent='flex-start'
               alignItems='stretch'
               spacing={4}
               sx={{ mb: 4 }}
            >
               <Grid item xs={12} sm={4}>
                  <Box className='howto'>
                     <img src={step1} width='100%' />
                     <Typography variant='h4'>
                        1. Enter your email to receive a unique ticker URL &
                        password
                     </Typography>
                  </Box>
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Box className='howto'>
                     <img src={step2} width='100%' />

                     <Typography variant='h4'>
                        2. Choose colors, font, and ticker on-screen position
                     </Typography>
                  </Box>
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Box className='howto'>
                     <img src={step3} width='100%' />

                     <Typography variant='h4'>
                        3. Input your ticker data, publish & update as often as
                        needed
                     </Typography>
                  </Box>
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Box className='howto'>
                     <img src={step4} width='100%' />

                     <Typography variant='h4'>
                        4. Use the viewer link to display the ticker in your
                        video switcher or app
                     </Typography>
                  </Box>
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Box className='howto'>
                     <img src={step5} width='100%' />

                     <Typography variant='h4'>
                        5. Ticker viewer will automatically update with new data
                        any time you click "publish"
                     </Typography>
                  </Box>
               </Grid>
            </Grid>
         </Stack>

         <Faq />

         <About />
      </Box>
   );
}

export default App;
