import React, {
   useState,
   useRef,
   useEffect,
   useContext,
   useCallback,
} from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { ScaleContext } from "../../context/scale";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function TickerWrapper({ children, preview, heightCallback }) {
   const columnWidth = useRef();
   const frameScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   useEffect(() => {
      heightCallback(1080 * frameScale);
   }, [frameScale]);

   const [lastResize, setLastResize] = useState();

   return (
      <Box
         sx={{
            width: "100%",
            height: 1080 * frameScale,
            position: "relative",
            outline: "1px solid #000",
         }}
         className={preview ? "transparentBG" : ""}
         ref={columnWidth}
         key={lastResize}
      >
         <ScaleContext.Provider value={{ frameScale }}>
            {children}
         </ScaleContext.Provider>
      </Box>
   );
}
