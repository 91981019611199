import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import _ from "lodash";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { motion, AnimatePresence, useInView } from "framer-motion";
import TickerElement from "./ticker/TickerElement";

import { ScaleContext } from "../context/scale";
import useRest from "../hooks/useRest";

function Ticker({ backgroundCallback }) {
   let { uuid } = useParams();

   const scale = React.useContext(ScaleContext).frameScale;
   const parentRef = useRef(null);

   const [tickerVisible, setTickerVisible] = useState(true);
   const [lastUpdate, setLastUpdate] = useState();

   const [
      instance,
      reminder,
      ticker,
      style,
      fonts,
      lastcheck,
      { subscribeTicker, getInstance, checkForUpdate },
   ] = useRest();

   const [elements, setElements] = useState([]);
   const [activeStyle, setActiveStyle] = useState();

   useEffect(() => {
      subscribeTicker(uuid);
      getInstance(uuid);
   }, []);

   useEffect(() => {
      if (instance) {
         checkForUpdate(uuid);
      }
   }, [instance]);

   useEffect(() => {
      if (style) {
         setActiveStyle(style);
         setLastUpdate(Date.now());
      }
   }, [style]);

   useEffect(() => {
      if (activeStyle && backgroundCallback) {
         backgroundCallback(activeStyle.viewer);
      }
   }, [activeStyle]);

   useEffect(() => {
      if (ticker && ticker.length > 0) {
         if (elements.length === 0 && ticker.length > 0) {
            handleAddTicker(0);
         }
      }
   }, [ticker]);

   useEffect(() => {
      setElements([]);
      handleAddTicker(0);
   }, [lastUpdate]);

   const handleAddTicker = (currentId) => {
      if (currentId === 0) {
         setElements([{ ...ticker[0], timestamp: Date.now() }]);
      } else {
         const currentIndex = _.findIndex(ticker, function (o) {
            return o.id == currentId;
         });

         const nextTicker = ticker[currentIndex + 1];

         setElements((prevState) => {
            let newState = [...prevState];
            if (nextTicker) {
               newState.push({ ...nextTicker, timestamp: Date.now() });
            } else {
               newState.push({ ...ticker[0], timestamp: Date.now() });
            }
            return newState;
         });
      }
   };

   const handleDeleteTicker = () => {
      setElements((prevState) => {
         let newState = [...prevState];
         newState.shift();
         return newState;
      });
   };

   return (
      <Box
         key={"tickerviewer-" + lastUpdate}
         sx={{
            width: 1920,
            height: 1080,
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
            position: "relative",
            overflow: "hidden",
         }}
         ref={parentRef}
      >
         {instance === false && (
            <Typography
               variant='h4'
               sx={{
                  whiteSpace: "nowrap",
                  color: "#f00",
                  backgroundColor: "#000",
                  p: 10,
                  width: 1920,
                  height: 1080,
               }}
            >
               An error occurred, this ticker does not exist.
            </Typography>
         )}

         {instance && (
            <>
               {tickerVisible && ticker && activeStyle && (
                  <>
                     <Box
                        key={"ticker"}
                        sx={{
                           position: "absolute",
                           ...activeStyle.ticker,
                           fontFamily: activeStyle.ticker.fontFamily,
                        }}
                        component={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{
                           opacity: 1,
                        }}
                        exit={{
                           opacity: 0,
                        }}
                        transition={{
                           duration: 0.5,
                        }}
                     >
                        <AnimatePresence>
                           {elements.length > 0 &&
                              elements.map((element, i) => (
                                 <TickerElement
                                    key={element.timestamp}
                                    element={element}
                                    activeStyle={activeStyle}
                                    addTicker={handleAddTicker}
                                    parentRef={parentRef}
                                    deleteTicker={handleDeleteTicker}
                                    duration={activeStyle.duration}
                                 />
                              ))}
                        </AnimatePresence>
                     </Box>
                     <Box
                        sx={{
                           position: "absolute",
                           top: 0,
                           left: 0,
                           ...activeStyle.viewer,
                           width: "100%",
                           height: "100%",
                           zIndex: "-1",
                        }}
                     />
                  </>
               )}
            </>
         )}
      </Box>
   );
}

export default Ticker;
