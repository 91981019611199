import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";

import _ from "lodash";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Delete from "@mui/icons-material/DeleteForever";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Footer from "../components/Footer";
import Ticker from "../components/Ticker";
import TickerPreview from "../components/TickerPreview";
import TickerWrapper from "../components/ticker/TickerWrapper";
import ColorPicker from "../components/editor/ColorPicker";
import FontMenu from "../components/editor/FontMenu";
import EditTickerEntry from "../components/editor/EditTickerEntry";

import useRest from "../hooks/useRest";

import logo from "../assets/logo.svg";

export default function Editor() {
   let { uuid } = useParams();

   const [
      instance,
      reminder,
      ticker,
      style,
      fonts,
      lastcheck,
      {
         subscribeTicker,
         checkForUpdate,
         addInstance,
         getInstance,
         getTicker,
         getStyle,
         saveStyle,
         saveTicker,
      },
   ] = useRest();

   const endRef = useRef(null);
   const scrollToBottom = () => {
      endRef.current?.scrollIntoView({ behavior: "smooth" });
   };

   const [messages, setMessages] = useState([]);
   const [messageLength, setMessageLength] = useState();
   const [frameHeight, setFrameHeight] = useState();
   const [activeStyle, setActiveStyle] = useState();
   const [editPreview, setEditPreview] = useState();
   const [mode, setMode] = useState("preview");
   const [showSave, setShowSave] = useState(false);
   const [edit, setEdit] = useState();
   const [needsPublish, setNeedsPublish] = useState(false);

   const [publishing, setPublishing] = useState(false);

   useEffect(() => {
      if (messageLength + 1 === messages.length) {
         scrollToBottom();
      }
      setMessageLength(messages.length);
   }, [messages]);

   useEffect(() => {
      subscribeTicker(uuid);
      getInstance(uuid);
   }, []);

   useEffect(() => {
      if (instance) {
         checkForUpdate(uuid);
      }
   }, [instance]);

   useEffect(() => {
      setMessages(ticker);
      setPublishing(false);
   }, [ticker]);

   useEffect(() => {
      if (activeStyle !== editPreview) {
         setShowSave(true);
      }
   }, [editPreview]);

   useEffect(() => {
      setShowSave(false);
      setActiveStyle(style);
      setEditPreview(style);
   }, [style]);

   const handleAddMessage = () => {
      if (messages.length < 30) {
         setNeedsPublish(true);

         setMessages((prevState) => {
            let newState = [...prevState];
            newState = [
               ...newState,
               {
                  message: "New message",
                  TickerInstanceId: uuid,
               },
            ];

            return newState;
         });

         scrollToBottom();
      }
   };

   const handleDeleteMessage = (e) => {
      setNeedsPublish(true);

      let id = e.currentTarget.id;

      const entryIndex = _.findIndex(messages, function (o) {
         return o.id == id;
      });

      console.log(id, entryIndex, messages);

      setMessages((prevState) => {
         let newState = [...prevState];
         newState.splice(entryIndex, 1);
         return newState;
      });
   };

   const handleSaveTicker = () => {
      saveTicker(uuid, messages);
      setNeedsPublish(false);
      setPublishing(true);
   };

   const handleSwitchChange = (e) => {
      const checked = e.target.checked;
      const parameter = e.target.name;
      const value = e.target.value;

      handleUpdateStyle("ticker", parameter, checked ? value : "");
   };

   const handleUpdate = (e) => {
      setEdit();
      setNeedsPublish(true);

      const id = e.target.dataset.id;
      const message = e.target.value;

      setMessages((prevState) => {
         let newState = [...prevState];
         newState[id] = { ...newState[id], message: message };
         return newState;
      });
   };

   const handleUpdateStyle = (parent, key, value) => {
      setEditPreview((prevState) => {
         let newState = { ...prevState };
         if (!key) {
            newState[parent] = value;
         } else {
            newState[parent][key] = value;
         }
         return newState;
      });
   };

   const handleUpdateSaveStyle = (parent, key, value) => {
      setEditPreview((prevState) => {
         let newState = { ...prevState };
         if (!key) {
            newState[parent] = value;
         } else {
            newState[parent][key] = value;
         }

         saveStyle(uuid, newState);

         return newState;
      });
   };

   const handleSaveStyle = () => {
      saveStyle(uuid, editPreview);
      setMode("preview");
   };

   const handleCancelSave = () => {
      setEditPreview(activeStyle);
      setMode("preview");
   };

   const handleFrameHeight = (height) => {
      if (height) {
         setFrameHeight(height);
      }
   };

   return (
      <>
         <Box
            className='pageBG'
            sx={{
               p: 4,
               width: "100%",
               minHeight: "100vh",
               overflow: "scroll",
               backgroundColor: "#333",
               color: "#fff",
               boxSizing: "border-box",
            }}
         >
            {/* HEADER */}
            <Stack direction='row' spacing={4} alignItems='center'>
               <img src={logo} width='100' />
               <Typography
                  variant='h2'
                  sx={{ whiteSpace: "nowrap", width: "100%" }}
               >
                  Just a Ticker
               </Typography>
            </Stack>

            {instance === false && (
               <Typography
                  variant='h3'
                  color='secondary'
                  sx={{
                     whiteSpace: "nowrap",
                     width: "100%",
                     mt: 4,
                  }}
               >
                  An error occurred, this ticker does not exist.
               </Typography>
            )}

            {instance && (
               <Grid
                  container
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  spacing={3}
                  sx={{ mt: 2 }}
               >
                  <Grid item md={5} lg={4} xl={3} xs={12}>
                     <Stack>
                        <Stack
                           direction='row'
                           spacing={4}
                           alignItems='flex-start'
                           justifyContent='flex-end'
                           sx={{ mb: 2 }}
                        >
                           <Typography
                              variant='h4'
                              sx={{
                                 width: "100%",
                                 mb: 2,
                                 whiteSpace: "nowrap",
                              }}
                           >
                              Ticker Entries
                           </Typography>

                           <Button
                              variant='contained'
                              color='primary'
                              onClick={handleAddMessage}
                              sx={{ width: 300, whiteSpace: "nowrap" }}
                              size='small'
                              disabled={publishing ? true : false}
                           >
                              + ADD ENTRY
                           </Button>
                        </Stack>

                        <Stack
                           sx={{
                              height: "70vh",
                              border: "1px solid #ccc",
                              backgroundColor: "#fff",
                              position: "relative",
                           }}
                           spacing={2}
                        >
                           {publishing && (
                              <Stack
                                 sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    backgroundColor: "#333",
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 1000,
                                    color: "#fff",
                                    opacity: 0.8,
                                 }}
                                 alignItems='center'
                                 justifyContent='center'
                              >
                                 <Box>Publishing...</Box>
                              </Stack>
                           )}

                           <Stack
                              direction='row'
                              alignItems='center'
                              sx={{ pt: 2, px: 3 }}
                           >
                              <Typography variant='h5' sx={{ width: "100%" }}>
                                 {messages.length}/30
                              </Typography>

                              <Button
                                 variant={
                                    needsPublish ? "contained" : "disabled"
                                 }
                                 color='secondary'
                                 onClick={handleSaveTicker}
                              >
                                 PUBLISH
                              </Button>
                           </Stack>
                           <Stack
                              sx={{
                                 height: "70vh",
                                 backgroundColor: "#fff",
                                 overflowY: "scroll",
                                 borderTop: "1px solid #ccc",
                                 p: 3,
                              }}
                              spacing={2}
                           >
                              {messages.length < 1 && (
                                 <Typography
                                    variant='h6'
                                    sx={{ color: "#999" }}
                                 >
                                    Please add your first entry.
                                 </Typography>
                              )}
                              {messages.length > 0 &&
                                 messages.map((entry, i) => (
                                    <Stack
                                       direction='row'
                                       key={"messageEdit-" + i}
                                    >
                                       <EditTickerEntry
                                          key={"entry" + entry.id}
                                          setEdit={setEdit}
                                          entry={entry}
                                          index={i}
                                          handleUpdate={handleUpdate}
                                       />

                                       <IconButton
                                          color='error'
                                          id={entry.id}
                                          onClick={handleDeleteMessage}
                                       >
                                          <Delete />
                                       </IconButton>
                                    </Stack>
                                 ))}
                              <div ref={endRef} />
                           </Stack>

                           <Stack
                              sx={{
                                 backgroundColor: "#fff",
                                 borderTop: "1px solid #ccc",
                                 p: 3,
                              }}
                           >
                              {lastcheck && (
                                 <Typography
                                    variant='h6'
                                    sx={{ color: "#999" }}
                                 >
                                    Last update check:{" "}
                                    {new Date(lastcheck).toLocaleTimeString(
                                       "en-US"
                                    )}
                                 </Typography>
                              )}
                           </Stack>
                        </Stack>

                        <Footer />
                     </Stack>
                  </Grid>

                  <Grid item md={7} lg={8} xl={9} xs={12}>
                     <Stack
                        sx={{
                           position: "relative",
                           userSelect: "none",
                        }}
                     >
                        <Stack
                           direction='row'
                           alignItems='flex-start'
                           justifyContent='flex-start'
                           spacing={4}
                           sx={{ mb: 3 }}
                        >
                           <Typography
                              variant='h6'
                              sx={{
                                 color: "#fff",
                                 whiteSpace: "nowrap",
                                 width: "100%",
                              }}
                           ></Typography>

                           <Button
                              variant='outlined'
                              color='secondary'
                              href={"/" + uuid + "/viewer"}
                              target='_new'
                              size='small'
                              sx={{ whiteSpace: "nowrap", px: 2 }}
                           >
                              OPEN VIEWER
                           </Button>
                        </Stack>

                        {editPreview && mode === "edit" && (
                           <>
                              <Stack
                                 direction='row'
                                 alignItems='center'
                                 justifyContent='center'
                              >
                                 <Box
                                    sx={{
                                       height: frameHeight,
                                       position: "relative",
                                    }}
                                 >
                                    <Slider
                                       min={0}
                                       max={1080}
                                       value={editPreview.ticker.bottom}
                                       orientation='vertical'
                                       sx={{
                                          height: "100%",
                                          '& input[type="range"]': {
                                             WebkitAppearance:
                                                "slider-vertical",
                                          },
                                       }}
                                       onChange={(e, val, prev) =>
                                          handleUpdateStyle(
                                             "ticker",
                                             "bottom",
                                             val
                                          )
                                       }
                                    />
                                 </Box>
                                 <Box
                                    sx={{
                                       height: frameHeight,
                                       overflow: "hidden",
                                    }}
                                 >
                                    <TickerWrapper
                                       preview
                                       heightCallback={handleFrameHeight}
                                       key='TickerWrapper'
                                    >
                                       <TickerPreview
                                          previewStyle={editPreview}
                                       />
                                    </TickerWrapper>
                                 </Box>
                              </Stack>

                              <Stack
                                 direction='row'
                                 sx={{ py: 3, margin: "auto" }}
                                 spacing={2}
                              >
                                 <Button
                                    variant='primary'
                                    onClick={handleSaveStyle}
                                    size='small'
                                 >
                                    SAVE LAYOUT
                                 </Button>
                                 <Button
                                    variant='secondary'
                                    onClick={handleCancelSave}
                                    size='small'
                                 >
                                    CANCEL
                                 </Button>
                              </Stack>

                              <Grid container spacing={2}>
                                 {/* Column 1 */}
                                 <Grid
                                    item
                                    xs={4}
                                    sx={{
                                       minWidth: 250,
                                       width: 250,
                                       textAlign: "center",
                                    }}
                                 >
                                    <Typography variant='h4' sx={{ mb: 2 }}>
                                       Font
                                    </Typography>

                                    <Stack spacing={3} alignItems='center'>
                                       <ColorPicker
                                          parent='ticker'
                                          property='color'
                                          initColor={editPreview.ticker.color}
                                          callback={handleUpdateStyle}
                                          inline
                                       />

                                       <Box sx={{ width: 225 }}>
                                          <FontMenu
                                             selected={
                                                editPreview.ticker.fontFamily
                                             }
                                             fonts={fonts}
                                             uuid={uuid}
                                             callback={(font) => {
                                                handleUpdateStyle(
                                                   "ticker",
                                                   "fontFamily",
                                                   font
                                                );
                                             }}
                                          />
                                       </Box>

                                       <Box sx={{ width: 225 }}>
                                          <Typography variant='h5'>
                                             Font Size
                                          </Typography>
                                          <Slider
                                             min={18}
                                             max={64}
                                             valueLabelDisplay='auto'
                                             value={editPreview.ticker.fontSize}
                                             onChange={(e, val, prev) =>
                                                handleUpdateStyle(
                                                   "ticker",
                                                   "fontSize",
                                                   val
                                                )
                                             }
                                          />
                                       </Box>

                                       <FormGroup>
                                          <FormControlLabel
                                             control={
                                                <Switch
                                                   checked={
                                                      editPreview.ticker
                                                         .textTransform ===
                                                      "uppercase"
                                                         ? true
                                                         : false
                                                   }
                                                   name='textTransform'
                                                   value='uppercase'
                                                   onChange={handleSwitchChange}
                                                   inputProps={{
                                                      "aria-label":
                                                         "controlled",
                                                   }}
                                                />
                                             }
                                             label='Uppercase'
                                             sx={{ color: "#333" }}
                                          />
                                       </FormGroup>
                                    </Stack>
                                 </Grid>

                                 {/* Column 2 */}
                                 <Grid
                                    item
                                    xs={4}
                                    sx={{
                                       minWidth: 250,
                                       width: 250,
                                       textAlign: "center",
                                    }}
                                 >
                                    <Typography variant='h4' sx={{ mb: 2 }}>
                                       Ticker
                                    </Typography>

                                    <Stack spacing={3} alignItems='center'>
                                       <ColorPicker
                                          parent='ticker'
                                          property='backgroundColor'
                                          initColor={
                                             editPreview.ticker.backgroundColor
                                          }
                                          callback={handleUpdateStyle}
                                          inline
                                       />

                                       <Box sx={{ width: 225 }}>
                                          <Typography variant='h5'>
                                             Padding
                                          </Typography>
                                          <Slider
                                             step={0.1}
                                             min={4}
                                             max={20}
                                             valueLabelDisplay='auto'
                                             value={editPreview.ticker.p}
                                             onChange={(e, val, prev) =>
                                                handleUpdateStyle(
                                                   "ticker",
                                                   "p",
                                                   val
                                                )
                                             }
                                          />
                                       </Box>

                                       <Box sx={{ width: 225 }}>
                                          <Typography variant='h5'>
                                             Spacing
                                          </Typography>
                                          <Slider
                                             step={1}
                                             min={0}
                                             max={150}
                                             valueLabelDisplay='auto'
                                             value={editPreview.message.pr}
                                             onChange={(e, val, prev) =>
                                                handleUpdateStyle(
                                                   "message",
                                                   "pr",
                                                   val
                                                )
                                             }
                                          />
                                       </Box>
                                    </Stack>
                                 </Grid>

                                 {/* Column 3 */}
                                 <Grid
                                    item
                                    xs={4}
                                    sx={{ minWidth: 250, textAlign: "center" }}
                                 >
                                    <Typography variant='h4' sx={{ mb: 2 }}>
                                       Viewer Background
                                    </Typography>

                                    <Stack spacing={3} alignItems='center'>
                                       <ColorPicker
                                          parent='viewer'
                                          property='backgroundColor'
                                          initColor={
                                             editPreview.viewer.backgroundColor
                                          }
                                          callback={handleUpdateStyle}
                                          inline
                                          swatches={[
                                             "transparent",
                                             "rgba(0,0,0,1)",
                                             "rgba(255,255,255,1)",
                                             "rgba(0, 177, 64,1)",
                                          ]}
                                       />
                                    </Stack>

                                    <Typography
                                       variant='h4'
                                       sx={{ mt: 6, mb: 2 }}
                                    >
                                       Speed
                                    </Typography>

                                    <Stack
                                       spacing={2}
                                       sx={{ width: "100%", px: 4 }}
                                       alignItems='center'
                                    >
                                       <Button
                                          fullWidth
                                          variant={
                                             editPreview.duration === 35
                                                ? "contained"
                                                : "outlined"
                                          }
                                          onClick={() => {
                                             handleUpdateSaveStyle(
                                                "duration",
                                                "",
                                                35
                                             );
                                          }}
                                       >
                                          Slow
                                       </Button>

                                       <Button
                                          fullWidth
                                          variant={
                                             editPreview.duration === 25
                                                ? "contained"
                                                : "outlined"
                                          }
                                          onClick={() => {
                                             handleUpdateSaveStyle(
                                                "duration",
                                                "",
                                                25
                                             );
                                          }}
                                       >
                                          Medium
                                       </Button>

                                       <Button
                                          fullWidth
                                          variant={
                                             editPreview.duration === 15
                                                ? "contained"
                                                : "outlined"
                                          }
                                          onClick={() => {
                                             handleUpdateSaveStyle(
                                                "duration",
                                                "",
                                                15
                                             );
                                          }}
                                       >
                                          Fast
                                       </Button>
                                    </Stack>
                                 </Grid>
                              </Grid>
                           </>
                        )}

                        {activeStyle && mode === "preview" && (
                           <>
                              <Box
                                 sx={{
                                    height: frameHeight,
                                    overflow: "hidden",
                                 }}
                              >
                                 <TickerWrapper
                                    preview
                                    heightCallback={handleFrameHeight}
                                    key='viewerPreviewWrapper'
                                 >
                                    <Ticker />
                                 </TickerWrapper>
                              </Box>

                              <Stack
                                 direction='row'
                                 alignItems='center'
                                 justifyContent='center'
                              >
                                 <Button
                                    variant='primary'
                                    onClick={() => setMode("edit")}
                                    sx={{ mt: 3, maxWidth: 300 }}
                                    fullWidth
                                 >
                                    EDIT LAYOUT
                                 </Button>
                              </Stack>
                           </>
                        )}
                     </Stack>
                  </Grid>
               </Grid>
            )}
         </Box>
      </>
   );
}
