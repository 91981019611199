import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import logo from "../assets/pdlogo.png";

import _ from "lodash";
import parse from "html-react-parser";

import usePages from "../hooks/usePages";

export default function Page() {
   let { urltitle } = useParams();

   const [pages, { getPages }] = usePages();

   useEffect(() => {
      getPages();
   }, []);

   const pageHtml = () => {
      return _.find(pages, { urlTitle: urltitle });
   };

   return (
      <Box
         className='pageBG'
         sx={{
            p: 8,
            width: "100%",
            boxSizing: "border-box",
         }}
      >
         <Stack direction='row' spacing={4} alignItems='center' sx={{ mb: 4 }}>
            <img src={logo} width='100' />
            <Typography
               variant='h2'
               sx={{ whiteSpace: "nowrap", width: "100%" }}
            >
               Just a Ticker
            </Typography>
            <Button
               variant='outlined'
               href='/'
               sx={{ whiteSpace: "nowrap", px: 3 }}
            >
               Back to Home
            </Button>
         </Stack>

         {pages && (
            <>
               {pageHtml() ? parse(pageHtml().html) : <Box>No page found</Box>}
            </>
         )}
      </Box>
   );
}
