import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function FontMenu({ selected, fonts, uuid, callback }) {
   const handleChange = (event) => {
      callback(event.target.value);
   };

   const handleClearClick = () => {
      callback("unset");
   };

   return (
      <Box
         sx={{
            width: "100%",
            backgroundColor: "rgba(255,255,255,.9)",
            boxSizing: "border-box",
         }}
      >
         <FormControl fullWidth>
            <Select
               labelId='styles-menu'
               id='styles-menu-select'
               value={selected ? selected : ""}
               onChange={handleChange}
               displayEmpty
               sx={{
                  "& .MuiSelect-iconOutlined": {
                     display: selected ? "none" : "",
                  },
                  "&.Mui-focused .MuiIconButton-root": {
                     color: "primary.main",
                  },
                  fontFamily: selected.split(".")[0],
                  fontSize: 18,
               }}
               endAdornment={
                  <IconButton
                     sx={{ visibility: selected ? "visible" : "hidden" }}
                     onClick={handleClearClick}
                  >
                     <ClearIcon />
                  </IconButton>
               }
            >
               <MenuItem value='unset' key={"font-" + selected}>
                  Default (Global)
               </MenuItem>
               {fonts &&
                  fonts.map((font, i) => (
                     <MenuItem
                        value={font}
                        key={"font-" + font + "-" + i}
                        sx={{
                           fontFamily: font,
                           fontSize: 20,
                        }}
                     >
                        {font}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>
      </Box>
   );
}
