import { useState, useEffect, useRef } from "react";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function usePages() {
   const init = {};

   const [pages, setPages] = useState();

   const handlers = {
      getPages: () => {
         axios.get(SERVER_URL + "/getpages").then(function (response) {
            setPages(response.data);
         });
      },
   };

   return [pages, handlers];
}
