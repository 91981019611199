import React, { useState, useContext, useEffect, useRef } from "react";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { motion, AnimatePresence, useInView } from "framer-motion";

import { ScaleContext } from "../context/scale";
import TickerElement from "./ticker/TickerElement";

export default function TickerDemo() {
   const scale = React.useContext(ScaleContext).frameScale;
   const parentRef = useRef(null);

   const [elements, setElements] = useState([]);

   const instance = {
      id: "1234-5678",
      email: "demo@justaticker.com",
      password: "",
      createdAt: "2023-04-11T06:40:34.000Z",
      updatedAt: "2023-04-11T06:40:34.000Z",
   };

   const ticker = [
      {
         id: 1,
         message: "Just a Ticker!",
      },
      {
         id: 2,
         message: "Crypto is up 25% today across the board",
      },
      {
         id: 3,
         message: "Did you know penguins dance the two-step?",
      },
      {
         id: 4,
         message: "Just a Ticker is free, seriously.",
      },
      {
         id: 5,
         message: "What a day, am I right?",
      },
   ];

   const activeStyle = {
      id: 1,
      ticker: {
         p: 6.4,
         left: 0,
         color: "rgba(245,247,245,1)",
         width: 1920,
         bottom: 0,
         fontSize: 40,
         fontFamily: "Noto Sans",
         textTransform: "none",
         backgroundColor: "#fd0c73",
      },
      message: { pr: 120 },
      viewer: { backgroundColor: "transparent" },
      duration: 15,
      createdAt: "2023-04-11T06:40:34.000Z",
      updatedAt: "2023-04-11T20:06:07.000Z",
      TickerInstanceId: "1234-5678",
   };

   useEffect(() => {
      if (ticker && ticker.length > 0) {
         if (elements.length === 0 && ticker.length > 0) {
            handleAddTicker(0);
         }
      }
   }, []);

   const handleAddTicker = (currentId) => {
      if (currentId === 0) {
         setElements([{ ...ticker[0], timestamp: Date.now() }]);
      } else {
         const currentIndex = _.findIndex(ticker, function (o) {
            return o.id == currentId;
         });

         const nextTicker = ticker[currentIndex + 1];

         setElements((prevState) => {
            let newState = [...prevState];
            if (nextTicker) {
               newState.push({ ...nextTicker, timestamp: Date.now() });
            } else {
               newState.push({ ...ticker[0], timestamp: Date.now() });
            }
            return newState;
         });
      }
   };

   const handleDeleteTicker = () => {
      setElements((prevState) => {
         let newState = [...prevState];
         newState.shift();
         return newState;
      });
   };

   return (
      <Box
         key={"tickerDemoViewer"}
         sx={{
            width: 1920,
            height: 1080,
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
            position: "relative",
            overflow: "hidden",
         }}
         ref={parentRef}
      >
         {instance && (
            <>
               {ticker && activeStyle && (
                  <>
                     <Box
                        key={"ticker"}
                        sx={{
                           position: "absolute",
                           ...activeStyle.ticker,
                           fontFamily: activeStyle.ticker.fontFamily,
                        }}
                        component={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{
                           opacity: 1,
                        }}
                        exit={{
                           opacity: 0,
                        }}
                        transition={{
                           duration: 0.5,
                        }}
                     >
                        <AnimatePresence>
                           {elements.length > 0 &&
                              elements.map((element, i) => (
                                 <TickerElement
                                    key={element.timestamp}
                                    element={element}
                                    activeStyle={activeStyle}
                                    addTicker={handleAddTicker}
                                    parentRef={parentRef}
                                    deleteTicker={handleDeleteTicker}
                                    duration={activeStyle.duration}
                                 />
                              ))}
                        </AnimatePresence>
                     </Box>
                     <Box
                        sx={{
                           position: "absolute",
                           top: 0,
                           left: 0,
                           ...activeStyle.viewer,
                           width: "100%",
                           height: "100%",
                           zIndex: "-1",
                        }}
                     />
                  </>
               )}
            </>
         )}
      </Box>
   );
}
