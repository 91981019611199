import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import pdlogo from "../assets/pdlogo.png";

export default function Footer() {
   return (
      <Box
         sx={{
            color: "#aaa",
            fontSize: ".7em",
            border: "1px solid #ccc",
            borderTop: "none",
            backgroundColor: "#eeeeee",
            p: 2,
            textAlign: "center",
         }}
      >
         <a
            href='https://punch-drunk.com'
            style={{ textDecoration: "none", border: 0, marginRight: "2em" }}
         >
            <img src={pdlogo} style={{ width: 80 }} />
         </a>
         <Stack
            spacing={2}
            justifyContent='center'
            alignItems='center'
            direction='row'
         >
            <a href='/'>Just a Ticker</a>
            <a href='/terms'>Terms &amp; Conditions</a>
            <a href='/privacy'>Privacy Policy</a>
         </Stack>
      </Box>
   );
}
