import { useState, useEffect, useRef } from "react";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function useRest() {
   const init = {};

   const [uuid, setUuid] = useState();
   const [lastcheck, setLastCheck] = useState();
   const [lastUpdate, setLastUpdate] = useState();
   const [lastStyleUpdate, setLastStyleUpdate] = useState();
   const [instances, setInstances] = useState();
   const [reminder, setReminder] = useState();
   const [ticker, setTicker] = useState([]);
   const [style, setStyle] = useState();
   const [fonts, setFonts] = useState([
      "Noto Sans",
      "Arial",
      "Helvetica",
      "Verdana",
      "Tahoma",
      "Impact",
      "Gill Sans",
      "Times New Roman",
   ]);

   useEffect(() => {
      if (lastUpdate) {
         handlers.getTicker(uuid);
      }
   }, [lastUpdate, uuid]);

   useEffect(() => {
      if (lastStyleUpdate) {
         handlers.getStyle(uuid);
      }
   }, [lastStyleUpdate, uuid]);

   // CHECK TIMER
   const currentTimer = useRef();

   useEffect(() => {
      return () => clearInterval(currentTimer.current);
   }, []);

   const startTimer = (uuid) => {
      currentTimer.current = setInterval(() => {
         handlers.checkForUpdate(uuid);
      }, 4000);
   };

   useEffect(() => {
      if (uuid) {
         startTimer(uuid);
      }
   }, [uuid]);

   const handlers = {
      subscribeTicker: (uuid) => {
         setUuid(uuid);
      },
      checkForUpdate: (uuid) => {
         setLastCheck(Date.now());
         axios
            .post(SERVER_URL + "/api/checkLastUpdate", {
               uuid: uuid,
            })
            .then(function (response) {
               if (response.data) {
                  setLastUpdate(response.data.updatedAt);
                  setLastStyleUpdate(response.data.Style.updatedAt);
               }
            });
      },
      getStyle: (uuid) => {
         axios
            .post(SERVER_URL + "/api/getStyle", {
               uuid: uuid,
            })
            .then(function (response) {
               if (response) {
                  setStyle(response.data);
               }
            });
      },
      saveStyle: (uuid, style) => {
         axios.post(SERVER_URL + "/api/saveStyle", {
            uuid: uuid,
            data: style,
         });
      },
      allTickerInstances: (email) => {
         axios
            .post(SERVER_URL + "/sendtickers", {
               email: email,
            })
            .then(function (response) {
               if (response === true) {
                  setReminder(true);
               }
            });
      },
      addInstance: (email) => {
         axios
            .post(SERVER_URL + "/api/createticker", {
               email: email,
            })
            .then(function (response) {
               if (response === true) {
                  setInstances(true);
               }
            });
      },
      getInstance: (uuid) => {
         axios
            .post(SERVER_URL + "/api/getInstance", {
               uuid: uuid,
            })
            .then(function (response) {
               if (!response) {
                  setInstances(false);
               } else {
                  setInstances(response.data);
               }
            });
      },
      getTicker: (uuid) => {
         axios
            .post(SERVER_URL + "/api/getTicker", {
               uuid: uuid,
            })
            .then(function (response) {
               setTicker(response.data);
            });
      },
      saveTicker: (uuid, messages) => {
         messages = messages.map((message) => {
            if (message.new) {
               delete message.id;
            }
            delete message.createdAt;
            delete message.updatedAt;

            return message;
         });

         axios.post(SERVER_URL + "/api/saveTicker", {
            uuid: uuid,
            data: messages,
         });
      },
   };

   return [instances, reminder, ticker, style, fonts, lastcheck, handlers];
}
