import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import _ from "lodash";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { ScaleContext } from "../context/scale";

import { motion, AnimatePresence, useInView } from "framer-motion";

import TickerPreviewElement from "./ticker/TickerPreviewElement";

export default function TickerPreview({ previewStyle }) {
   let { uuid } = useParams();

   const scale = React.useContext(ScaleContext).frameScale;
   const parentRef = useRef(null);

   const [elements, setElements] = useState([
      { message: "Preview Ticker Entry" },
      { message: "Preview Ticker Entry" },
      { message: "Preview Ticker Entry" },
      { message: "Preview Ticker Entry" },
      { message: "Preview Ticker Entry" },
      { message: "Preview Ticker Entry" },
   ]);
   const [activeStyle, setActiveStyle] = useState();

   useEffect(() => {
      setActiveStyle(previewStyle);
   }, [previewStyle]);

   return (
      <Box
         key={"tickerPreview"}
         sx={{
            width: 1920,
            height: 1080,
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
            position: "relative",
            overflow: "hidden",
         }}
         ref={parentRef}
      >
         {activeStyle && (
            <>
               <Box
                  key={"ticker"}
                  sx={{
                     position: "absolute",
                     ...activeStyle.ticker,
                     fontFamily: activeStyle.ticker.fontFamily,
                  }}
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{
                     opacity: 1,
                  }}
                  exit={{
                     opacity: 0,
                  }}
                  transition={{
                     duration: 0.5,
                  }}
               >
                  <AnimatePresence>
                     <Box
                        sx={{
                           position: "relative",
                           whiteSpace: "nowrap",
                        }}
                     >
                        <Stack
                           direction='row'
                           sx={{
                              position: "absolute",
                           }}
                        >
                           {elements.length > 0 &&
                              elements.map((element, i) => (
                                 <TickerPreviewElement
                                    key={
                                       "element" + i + "-" + element.timestamp
                                    }
                                    element={element}
                                    activeStyle={activeStyle}
                                    duration={activeStyle.duration}
                                 />
                              ))}
                        </Stack>
                     </Box>
                  </AnimatePresence>
               </Box>
               <Box
                  sx={{
                     position: "absolute",
                     top: 0,
                     left: 0,
                     ...activeStyle.viewer,
                     width: "100%",
                     height: "100%",
                     zIndex: "-1",
                  }}
               />
            </>
         )}
      </Box>
   );
}
