import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

export default function EditTickerEntry({
   setEdit,
   entry,
   index,
   handleUpdate,
}) {
   const maxLength = 60;
   const [tooLong, setTooLong] = useState(false);

   const handleChange = (e) => {
      if (e.target.value.length >= maxLength) {
         setTooLong(true);
      }

      handleUpdate(e);
   };

   return (
      <Stack
         direction='row'
         alignItems='center'
         spacing={2}
         sx={{ width: "100%" }}
      >
         <Typography variant='h5' sx={{ fontWeight: "bold" }}>
            {index + 1}
         </Typography>
         <TextField
            inputProps={{
               "data-id": index,
               tabIndex: index + 1,
               maxLength: maxLength,
            }}
            defaultValue={entry.message}
            onChange={handleChange}
            label={tooLong ? "Maximum Length" : ""}
            sx={{ backgroundColor: tooLong ? "#ffe0e1" : "none" }}
            fullWidth
         />
      </Stack>
   );
}
