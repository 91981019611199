import React, { useState } from "react";
import { Stack, Box, Button, Typography, TextField } from "@mui/material";

import axios from "axios";
import { useFormik } from "formik";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function RemindMeTickers() {
   const [reminder, setReminder] = useState(false);

   const validate = (values) => {
      const errors = {};

      if (!values.email) {
         errors.email = "Required";
      } else if (
         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
         errors.email = "Invalid email address";
      }

      return errors;
   };
   const findTickers = useFormik({
      initialValues: {
         email: "",
      },
      validate,
      onSubmit: (values) => {
         allTickerInstances(values.email);
      },
   });

   const allTickerInstances = (email) => {
      if (email) {
         axios
            .post(SERVER_URL + "/sendtickers", {
               email: email,
            })
            .then(function (response) {
               if (response) {
                  setReminder(response.data);
               }
            });
      }
   };

   return (
      <Box sx={{ my: 2, maxWidth: 500, width: "100%" }}>
         {!reminder && (
            <form onSubmit={findTickers.handleSubmit}>
               <Stack>
                  <TextField
                     type='email'
                     label='Email Address'
                     name='email'
                     id='email'
                     onChange={findTickers.handleChange}
                     onBlur={findTickers.handleBlur}
                     value={findTickers.values.email}
                  />

                  {findTickers.touched.email && findTickers.errors.email && (
                     <Typography variant='subtitle2'>
                        {findTickers.errors.email}
                     </Typography>
                  )}

                  <Button
                     name='submit'
                     type='submit'
                     variant='contained'
                     color='secondary'
                     disabled={
                        findTickers.errors.email
                           ? true
                           : !findTickers.values.email
                           ? true
                           : false
                     }
                     sx={{ mt: 2 }}
                  >
                     Submit
                  </Button>
               </Stack>
            </form>
         )}

         {reminder && (
            <Typography variant='h4' color='secondary'>
               {reminder}
            </Typography>
         )}
      </Box>
   );
}
